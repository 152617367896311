import { createSlice } from "@reduxjs/toolkit";
import { getCustomerDealing, getCustomers } from "../thunks/customers";

const initialState = {
  customers: [],
  positions: {},
  isPending: true,
  isPositionPending: true,
  isExporting: false,
  exportProgress: 10,
  customerFilters: {
    currentPage: 0,
  },
  positionFilters: {
    currentPage: 0,
    perPage: 10,
  },
  autodialStarted: false,
  autodialClientId: null,
  customerIds: [],
  currentPage: 1,
  // customFilter: []
  customFilter: [],
  sorting: [],
  emailPhoneSearch : {
    email: undefined,
    phone: undefined,
  },
  emails : [],
  phones : [],
};

const reducers = {
  setEmailPhoneQuery(state, action) {
    const searchQuery = action.payload;
    state.emailPhoneSearch ={
      email : searchQuery?.email ?? state.emailPhoneSearch.email,
      phone : searchQuery?.phone ?? state.emailPhoneSearch.phone,
    }
  },
  updateEmails(state, action) {
    state.emails = [...state.emails, ...action.payload]?.filter((email, index, arr) => 
      arr.findIndex(e => e.value === email.value) === index
    ) ?? [];
  },
  updatePhones(state, action) {
    state.phones = [...state.phones, ...action.payload]?.filter((phone, index, arr) => 
      arr.findIndex(e => e.value === phone.value) === index
    ) ?? [];
  },
  setFilters(state, action) {
    const customers = action.payload;
    state.customerFilters = { ...state.customerFilters, currentPage: 0, ...customers };
  },
  setPositionFilters(state, action) {
    const dealing = action.payload;
    state.positionFilters = { ...state.positionFilters, ...dealing };
  },
  resetPositionFilter(state) {
    state.positionFilters = {
      currentPage: state.positionFilters?.currentPage,
      perPage: state.positionFilters?.perPage,
    };
  },
  setCurrentPage(state, action) {
    const page = action.payload;
    state.currentPage = page;
  },
  resetFilter(state) {
    state.customerFilters = {};
  },
  setIsExporting(state, action) {
    const isExporting = action.payload;
    state.isExporting = isExporting;
  },
  setExportProgress(state, action) {
    const exportProgress = action.payload;
    state.exportProgress = exportProgress;
  },
  setCustomFilter(state, action) {
    const customFilter = action.payload;
    state.customFilter = customFilter;
  },
  setCustomerSorting(state, action) {
    const customerSorting = action.payload;
    state.sorting = customerSorting;
  },
  setAutodialStarted(state, action) {
    const autodial = action.payload;
    state.autodialStarted = autodial;
  },
  setAutodialClientId(state, action) {
    const id = action.payload;
    state.autodialClientId = id;
  },
  resetAll(state) {
    state.customers = [];
    state.customFilter = [];
    state.customerFilters = {};
    state.clientIds = [];
  }
};

const extraReducers = (builder) => {
  builder
    .addCase(getCustomers.fulfilled, (state, action) => {
      const currentPage = state?.currentPage;
      const customers = action.payload;
      state.customers = customers;
      const ids =
        currentPage === 1
          ? [...customers.clients?.map((client) => client?.id)]
          : [
            ...state.customerIds,
            ...customers.clients?.map((client) => client?.id),
          ];
      state.customerIds = [...new Set(ids)];
      state.isPending = false;
    })
    .addCase(getCustomers.pending, (state) => {
      state.isPending = true;
    })
    .addCase(getCustomerDealing.fulfilled, (state, action) => {
      const dealing = action.payload;
      state.positions = dealing;
      state.isPositionPending = false;
    })
    .addCase(getCustomerDealing.pending, (state) => {
      state.isPositionPending = true;
    })
};

export const slice = createSlice({
  name: "customers",
  initialState,
  reducers,
  extraReducers,
});

export const { reducer } = slice;
