import PropTypes from "prop-types";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import EditIcon from "@untitled-ui/icons-react/build/esm/Edit02";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { Scrollbar } from "src/components/scrollbar";
import { ReminderRenderContent } from "./reminder-render-content";
import Iconify from 'src/components/iconify';
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";

export const RemindersPopover = (props) => {
  const {
    anchorEl,
    reminders,
    onClose,
    onMarkAllAsRead,
    onRemoveOne,
    onEditOne,
    open = false,
    ...other
  } = props;
  const router = useRouter();

  const handleOpenCalendar = () => {
    router.push(paths.dashboard.calendar);
    onClose();
  }

  const isEmpty = reminders?.length === 0;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 450 } }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography
          color="inherit"
          variant="h6">
          Reminders
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Tooltip title="Mark all as read">
            <IconButton
              onClick={onMarkAllAsRead}
              size="small"
              color="inherit">
              <Iconify icon="mage:email" width={22}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Open calendar">
            <IconButton
              onClick={handleOpenCalendar}
              size="small"
              color="inherit">
              <Iconify icon="lucide:calendar" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      {isEmpty ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">There are no reminders</Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400 }}>
          <List disablePadding>
            {reminders?.map((reminder) => (
              <ListItem
                divider
                key={reminder.id}
                sx={{
                  alignItems: "flex-start",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                  "& .MuiListItemSecondaryAction-root": {
                    top: "24%",
                  },
                }}
                secondaryAction={
                  <Stack
                    direction="row"
                    alignItems="center">
                    <Tooltip title="Edit">
                      <IconButton
                        edge="end"
                        onClick={() => onEditOne?.(reminder)}
                        size="small"
                      >
                        <SvgIcon>
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton
                        edge="end"
                        onClick={() => onRemoveOne?.(reminder?.id)}
                        size="small"
                      >
                        <SvgIcon>
                          <XIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                }
              >
                {ReminderRenderContent(reminder)}
              </ListItem>
            ))}
          </List>
        </Scrollbar>
      )}
    </Popover>
  );
};

RemindersPopover.propTypes = {
  anchorEl: PropTypes.any,
  reminders: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  onRemoveOne: PropTypes.func,
  open: PropTypes.bool,
};
